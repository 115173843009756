:root {
    --header-height: 75px;
    --subheader-height: 60px;

    --box-shadow: 0 3px 5px rgba(57, 63, 72, 0.4);
    --top-shadow: 0px -3px 3px rgba(57, 63, 72, 0.4);
    --button-shadow: 0 3px 5px rgba(57, 63, 72, 0.4);
    --tab-shadow: 2px -3px 3px rgba(57, 63, 72, 0.4);
    --file-card-shadow: 2px 6px 6px rgba(57, 63, 72, 0.4);
    --sidebar-shadow: 1.5px 3px 8px rgba(57, 63, 72, 0.4);
    --canvas-shadow: 0 0 6px 1px rgba(57, 63, 72, 0.4);
    --canvas-header-shadow: 0 1.5px 6px 1px rgba(57, 63, 72, 0.4);
    --active-shadow: 0 3px 5px rgba(244, 120, 83);

    --black: #231f20;
    --white: #fff;
    --grey: #e5e3e2;
    --light-grey: #f0f0f0;
    --primary: #8cc640;
    --primary-light: #cef797;
    --secondary: #f47853;
    --secondary-light: #f4ccc1;
    --material-btn: #a7a7a7;
    --material-btn-bg: rgba(0, 0, 0, 0.1);
}

@font-face {
    font-family: 'Mallows';
    src: url(./fonts/Mallows.ttf);
}

@font-face {
    font-family: 'Fashion';
    src: url(./fonts/Fashion.ttf);
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: var(--black);
}

#root {
    width: 100vw;
    height: 100vh;
}

.material-icons {
    user-select: none;
}

div[role="tooltip"] > * {
    pointer-events: none;
    font-size: 18px !important;
    font-family: 'Roboto', sans-serif;
}

.copyright {
    text-align: center;
}

.backdrop {
    backdrop-filter: blur(4px);
}
